import { useMutation } from '@tanstack/react-query';
import { rateDashboardWidget } from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { FeedbackTypes, queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

export const updateWidgetFeedbackCachedData = (
  widgetId: DashboardComponent['id'],
  dashboardId: string
) => {
  const prevDashboardData: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(dashboardId)
  );

  if (!prevDashboardData) return;

  const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
    if (widget.id === widgetId) {
      return {
        ...widget,
        hasFeedback: true
      };
    }
    return widget;
  });

  if (updatedWidgets?.length) {
    queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
      ...prevDashboardData,
      widgets: updatedWidgets
    });
  }
};

export const useRateChatWidget = (dashboardId: string) => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      widgetId: DashboardComponent['id'];
      data: {
        feedback: FeedbackTypes;
        text?: string;
        dashboardId?: string;
        widgetType: string;
      };
    }
  >({
    mutationFn: ({ widgetId, data }) => {
      return rateDashboardWidget({
        widgetId,
        text: data.text,
        feedbackType: data.feedback,
        dashboardId: dashboardId || ''
      });
    },
    onError(error, { widgetId }) {
      if (error.message === 'Widget already rated') {
        snackbar.error.commonError(error);
        updateWidgetFeedbackCachedData(widgetId, dashboardId);
        return;
      }

      snackbar.error.commonError(error);
    },
    onSuccess(_, { widgetId, data }) {
      mixpanel?.track(MixpanelEvents.WidgetFeedback, {
        Feedback: data.feedback,
        'Feedback Text': data.text,
        'Widget ID': widgetId,
        'Widget Type': data.widgetType,
        'Dashboard ID': data.dashboardId
      });

      updateWidgetFeedbackCachedData(widgetId, dashboardId);
    }
  });
};
