/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import { LngLatLike, MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { MapTypes } from 'enums';
import { useLanguage } from 'hooks';
import { SelectedFilterItem } from 'views/DashboardV2/WidgetFilter';

import {
  DEFAULT_CLUSTER_LAYER_ID,
  DEFAULT_CLUSTER_SOURCE_ID,
  DEFAULT_REGIONS_LAYER_ID
} from '../constants/mapSettings';
import { MapBoundaries, MapSizes } from '../Map';

interface Props {
  data?: DashboardParamsData[] | null;
  mapRef?: RefObject<MapRef>;
  mapType?: MapTypes;
  filters?: string[];
  mapSizes: MapSizes | null;
  containerRef: RefObject<HTMLDivElement>;
  mapBoundaries: MapBoundaries | null;
  headerRef: RefObject<HTMLDivElement>;
  setMapType: Dispatch<SetStateAction<MapTypes>>;
  setMapSizes: Dispatch<SetStateAction<MapSizes | null>>;
  setGroupFilter: Dispatch<SetStateAction<string[]>>;
  setMapBoundaries: Dispatch<SetStateAction<MapBoundaries | null>>;
  setSelectedRegionOnMap: Dispatch<SetStateAction<SelectedFilterItem>>;
}

export const useMap = ({
  data,
  mapRef,
  mapType,
  filters,
  mapSizes,
  headerRef,
  containerRef,
  mapBoundaries,
  setMapType,
  setMapSizes,
  setGroupFilter,
  setMapBoundaries,
  setSelectedRegionOnMap
}: Props) => {
  const language = useLanguage();

  const resizeMap = () => {
    setTimeout(() => {
      if (containerRef?.current && headerRef?.current) {
        setMapSizes({
          width: containerRef.current.offsetWidth,
          height:
            containerRef.current.offsetHeight - headerRef.current.offsetHeight
        });
      }
    }, 150);
  };

  const getMapBoundaries = () => {
    const coordinates = (data as DashboardParamsData[]).reduce(
      (result: Array<[number, number, number?, number?]>, { value }) => [
        ...result,
        ...value
      ],
      []
    );
    const minLatitude = Math.min(...coordinates.map((c) => c[0]));
    const maxLatitude = Math.max(...coordinates.map((c) => c[0]));
    const minLongitude = Math.min(...coordinates.map((c) => c[1]));
    const maxLongitude = Math.max(...coordinates.map((c) => c[1]));

    setMapBoundaries({
      minLatitude,
      maxLatitude,
      minLongitude,
      maxLongitude
    });
  };

  const fitToBounds = () => {
    if (mapRef?.current && mapBoundaries) {
      mapRef?.current?.fitBounds(
        [
          [mapBoundaries.minLongitude, mapBoundaries.minLatitude],
          [mapBoundaries.maxLongitude, mapBoundaries.maxLatitude]
        ],
        { padding: 40, duration: 1000 }
      );
    }
  };

  const setRegionLayerHandlers = () => {
    mapRef?.current?.on(
      'click',
      DEFAULT_REGIONS_LAYER_ID,
      (e: MapLayerMouseEvent) => {
        const feature = e.features?.[0];
        const value = {
          code: feature?.properties?.code,
          name:
            language === 'de'
              ? feature?.properties?.ux_name_de
              : feature?.properties?.ux_name_en
        };

        setSelectedRegionOnMap(value);
      }
    );

    mapRef?.current?.on('mouseenter', DEFAULT_REGIONS_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = 'pointer';
      }
    });

    mapRef?.current?.on('mouseleave', DEFAULT_REGIONS_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = '';
      }
    });
  };

  const setClusterLayerHandlers = () => {
    mapRef?.current?.on('click', DEFAULT_CLUSTER_LAYER_ID, (e) => {
      if (mapRef?.current) {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: [DEFAULT_CLUSTER_LAYER_ID]
        });

        if (features?.length && features[0] != null) {
          const feature = features[0];
          const clusterId = feature?.properties?.cluster_id;
          const source = mapRef.current.getSource(
            DEFAULT_CLUSTER_SOURCE_ID
          ) as mapboxgl.GeoJSONSource;

          if (clusterId != null) {
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              const center = (feature.geometry as GeoJSON.Point)
                .coordinates as LngLatLike;

              mapRef?.current?.easeTo({
                zoom,
                center
              });
            });
          }
        }
      }
    });

    mapRef?.current?.on('mouseenter', DEFAULT_CLUSTER_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = 'pointer';
      }
    });

    mapRef?.current?.on('mouseleave', DEFAULT_CLUSTER_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = '';
      }
    });
  };

  const handleMapLoad = () => {
    fitToBounds();
    setClusterLayerHandlers();
    setRegionLayerHandlers();
  };

  useEffect(() => {
    if (filters?.length) {
      setGroupFilter(filters);
    }
  }, []);

  useEffect(() => {
    if (mapType) {
      setMapType(mapType);
    }
  }, [mapType]);

  useEffect(() => {
    setGroupFilter(filters || []);
  }, [filters?.length]);

  useEffect(() => {
    if (mapType) {
      setMapType(mapType);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeMap);

    return () => {
      window.removeEventListener('resize', resizeMap);
    };
  }, []);

  useEffect(() => {
    if (data?.length && !mapBoundaries) {
      if (window.Worker) {
        const mapWorker = new Worker('/workers/mapWorker.js');

        mapWorker.onmessage = (event: MessageEvent) => {
          setMapBoundaries({ ...(event.data as MapBoundaries) });

          mapWorker.terminate();
        };

        mapWorker.postMessage(data);
      } else {
        getMapBoundaries();
      }
    }

    return () => {
      setMapBoundaries(null);
    };
  }, []);

  useEffect(() => {
    mapRef?.current?.resize();
  }, [mapSizes?.width, mapSizes?.height]);

  useEffect(() => {
    fitToBounds();
  }, [mapBoundaries, mapSizes]);

  return {
    handleMapLoad
  };
};
