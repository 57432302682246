export enum SSEEvents {
  NewChat = 'new-chat',
  Notification = 'notification',
  DashboardTitle = 'dashboard-title',
  NewChatMessage = 'new-chat-message',
  UnauthorizedError = 'unauthorized-error',
  DashboardTextWidgets = 'dashboard-text-widgets',
  DashboardUpdate = 'dashboard-update',
  DashboardUpdateWidgetFilter = 'dashboard-update-widget-filter',
  DashboardRateWidget = 'dashboard-rate-widget',
  DashboardCreateNested = 'dashboard-create-nested'
}
